import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useEffect, useState } from 'react'
import DateTimeInput from '../../components/inputs/DateTimeInput'
import SearchInput from '../../components/inputs/SearchInput'
import SelectInput from '../../components/inputs/SelectInput'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import {
  showErrorMessage,
  showSuccessMessage
} from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import RequiredLabel from '../../components/utils/RequiredLabel'
import { getEmpresaDTO } from '../../dtos/cadastro/EmpresaDTO'
import CommonHelper from '../../helpers/CommonHelper'
import {
  formatCnpj,
  formatDateTime,
  formatDateTimeSQL
} from '../../helpers/formaters'
import { getStore } from '../../redux/store'
import AtualizacaoService from '../../services/cadastro/AtualizacaoService'
import EmpresaService from '../../services/cadastro/EmpresaService'
import VersaoService from '../../services/cadastro/VersaoService'

const AgendarAtualizacao = ({ visible, onHide, empresaSelect, isNew }) => {
  const [empresa, setEmpresa] = useState(null)
  const [sugestaoEmpresa, setSugestaoEmpresa] = useState([])
  const [selecionadaVersao, setSelecionadaVersao] = useState('')
  const [observacao, setObservacao] = useState('')
  const [versao, setVersao] = useState('')
  const [dataAgendada, setDataAgendada] = useState('')
  const [loading, setLoading] = useState(true)
  const [desativado, setDesativado] = useState(true)

  useEffect(async () => {
    const tipoAcessos = await VersaoService.getAll()
    setVersao(tipoAcessos)
    if (isNew) {
      setEmpresa('')
      setSelecionadaVersao('')
      setDataAgendada('')
      setObservacao('')
      setDesativado(false)
      setLoading(false)
    }
    if (empresaSelect && empresaSelect.codigo_atu && isNew === false) {
      const dados = await AtualizacaoService.get(empresaSelect.codigo_atu)
      if (dados.length > 0) {
        setEmpresa(dados[0].codigoEmp === undefined ? '' : dados[0].codigoEmp)
        setSelecionadaVersao(dados[0].VersaoNov)
        setDataAgendada(dados[0].datage_atu)
        setObservacao(dados[0].observacao_atu)
        setLoading(false)
        setDesativado(true)
      }
    }
  }, [empresaSelect, isNew])
  const sugerirEmpresa = async () => {
    try {
      const empresaAtualizacao = new EmpresaService()

      const Empresas = (
        await empresaAtualizacao.filter(`descricaoFiltro=${empresa.nomfan_emp}`)
      ).data

      setSugestaoEmpresa(Empresas)
    } catch (error) {
      showErrorMessage(
        error.message || 'Ocorreu um erro inesperado ao sugerir uma ITE!'
      )
    }
  }
  const handleUpdateAgendar = async () => {
    try {
      await AtualizacaoService.save(
        empresa.codigo_emp,
        empresa.codver_emp === null ? '10538' : empresa.codver_emp,
        selecionadaVersao.codigo_ver,
        dataAgendada,
        observacao,
        getStore().usuarioLogado.codigo_usu,
        empresaSelect.codigo_atu
      )
      showSuccessMessage('Atualização agendada com sucesso')
      hideModal()
      // await AtualizacaoService.getAll()

      location.reload()
    } catch (error) {
      if (error.message === '') {
        return showErrorMessage('Preencha os campos')
      }
      showErrorMessage(error.message)
    }
  }

  function handleEmpresa (event) {
    setEmpresa({ nomfan_emp: event.target.value })
  }

  function hideModal () {
    setEmpresa('')
    setSelecionadaVersao('')
    setDataAgendada('')
    setObservacao('')
    onHide()
  }

  return (
    <Modal
      header='Agendar atualização'
      width={55}
      onHide={hideModal}
      visible={visible}
      footer={<Button label='Agendar' onClick={() => handleUpdateAgendar()} />}
    >
      {loading ? (
        <div className='loading-container'>
          <ProgressSpinner />
        </div>
      ) : (
        <div className='formgrid grid'>
          <div className='field col-12'>
            <RequiredLabel label='Empresa ' />
            <SearchInput
              AutoComplete
              field='nomfan_emp'
              disabled={desativado}
              value={empresa}
              suggestions={sugestaoEmpresa}
              completeMethod={sugerirEmpresa}
              onChange={handleEmpresa}
              onSelect={(e) => setEmpresa(e.value)}
              className='inputfield w-full'
              FiltroModal
              filtroTitle='Pesquisar Empresa'
              service={EmpresaService}
              model={getEmpresaDTO}
              columns={[
                { campo: 'nomfan_emp', nome: 'Nome' },
                { campo: 'cnpj_emp', nome: 'CNPJ', format: formatCnpj }
              ]}
            />
          </div>
          <div className='field col-12 md:col-6'>
            <RequiredLabel label='Atualizar para a versão ' />
            <SelectInput
              value={selecionadaVersao && selecionadaVersao.nome_ver}
              filter={CommonHelper.isDesktop()}
              className=' w-full'
              optionLabel='nome_ver'
              dataKey='codigo_ver'
              options={versao.data}
              onChange={(e) => setSelecionadaVersao(e.target.value)}
              noFloatLabel
            />
          </div>
          <div className='field col-12 md:col-6'>
            <RequiredLabel label='Agendar atualização para ' />
            <DateTimeInput
              name='dataAgendada'
              placeholder={dataAgendada && formatDateTime(dataAgendada)}
              className='inputfield w-full'
              value={dataAgendada && dataAgendada}
              onChange={(e) =>
                setDataAgendada(formatDateTimeSQL(e.target.value))
              }
            />
          </div>
          <div className='field col-12 md:col-12'>
            <TextAreaInput
              label='Observações'
              value={observacao}
              name='obs_emp'
              onChange={(e) => setObservacao(e.target.value)}
              className={'inputfield w-full'}
              placeholder='Observações'
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default AgendarAtualizacao
